import React from "react"
import Layout from "../../components/layout/Layout.js"
import { Row, BlogRow } from "../../components/row/Row.js"
import {
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { TextMedium } from "../../components/text/Text.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/social-media-strategy-saas.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { ImageHome } from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import AirtableForm from "../../components/airtableForm/AirtableForm.js"
import { Contact, ButtonContainer } from "../../components/button/Button.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"


function SocialMediaStrategy({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="SaaS Social Media Strategy Template" 
       description="This 87-page document includes guidelines for setting up your organic and paid ads strategy on LinkedIn, along with 180 content ideas for SaaS companies, 
       and a series of templates you can use for organic and paid posts."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/saas-social-media-strategy-template/">SaaS Social Media Strategy Template</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="SaaS Social Media Strategy Template" />
            <SubHeading subheading="This template includes guidelines for setting up your organic and paid ads strategy on LinkedIn, along with content ideas 
              and a series of ready-to-use templates for organic and paid posts." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/social-media-strategy-saas" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="Social Media Strategy for SaaS Companies" />
          </Column50>
        </Row>
      </AboveFold>

      <Section>
        <BlogRow isCentered>
          <TextMedium
              textMedium="The goal of this document is to help you build an effective strategy for growing your LinkedIn channel and turning it into a lead and deal generation source. 
              You can apply the principles to other social media channels as well." /> 
          <TextMedium textMedium="You'll receive the .pdf and .ppt documents, along with access to a list of 180 content ideas for B2B SaaS companies, 
          and a set of 37 templates for social media posts (organic and paid)." /> 
          <GumroadForm
                formName="SaaS LinkedIn Strategy Template"
                redirectPage="https://socontent.gumroad.com/l/social-media-strategy-saas"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/social-media-strategy-saas"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
            />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default SocialMediaStrategy
