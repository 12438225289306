import React from 'react';
import { Column100 } from "../column/Column"
import { ButtonContainer } from "../button/Button.js"
import { FormRow, FormField, StyledForm, StyledFormButton, StyledCheckboxGroup } from './style'
import { FormLabel } from '../form/style';


const FormButton = ({ buttonText, buttonLink }) => (
  <StyledFormButton to={buttonLink}>{buttonText}</StyledFormButton>
)
class AirtableForm extends React.Component { 

constructor(props) {
  super(props);
  this.state = {
    firstname: ' ', 
    lastname: ' ', 
    email: ' ', 
    agree: false, 
  };
  
  this.updateFirstName = this.updateFirstName.bind(this);
  this.updateLastName = this.updateLastName.bind(this);
  this.updateEmail = this.updateEmail.bind(this);
  this.updateAgreement = this.updateAgreement.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
}

updateFirstName(event) {
  console.log(this.state.firstname);
  this.setState({
    firstname: event.target.value
  })
} 

updateLastName(event) {
  console.log(this.state.lastname);
  this.setState({
    lastname: event.target.value
  })
} 

updateEmail(event) {
  console.log(this.state.email);
  this.setState({
    email: event.target.value
  })
} 

updateAgreement(event) {
  console.log('Checkbox clicked');
  this.setState({
    agree: event.target.checked
  })
}

handleSubmit(event) {
  event.preventDefault();

  let data = {
    "records": [
      {
        "fields": {
          "First name": this.state.firstname,
          "Last name": this.state.lastname,
          "Email": this.state.email,
          "Form name": this.props.formName,
          "Agree": this.state.agree       
        },
      },
    ],
    typecast: true 
  }

  let final_data = JSON.stringify(data);

  fetch(`https://api.airtable.com/v0/appWZOog67McA97vM/Main`, {
    method: "POST",
    body: final_data,
    headers: {
      "Authorization": `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`,
      "Content-Type": "application/json"
    },
  })
  .then( response => {
    console.log(response)
    window.location = "https://www.schoolofcontent.net/download-success/" 
  })
  .catch( error => console.log(error) )



  // axios.post('https://api.airtable.com/v0/appWZOog67McA97vM/Main/', 
  // final_data, 
  // {
  //   headers: {
  //   'Authorization': `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`,
  //   'Accept': 'application/json', 
  //   'Content-Type': 'application/json'
  // }
  // })
  // .then( response => {
  //   console.log(response)
  //   window.location = "https://www.schoolofcontent.net/download-success/"}
  // )
  // .catch(error => {
  //   console.log(error);
  //   window.location = "https://www.schoolofcontent.net/404/"
  // })


}

render() {

  return (
    <div >
      <StyledForm 
        onSubmit={this.handleSubmit} 
        method="POST" 
        name={this.props.formName}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        redirect={this.props.redirectPage} 
        > 
        <FormRow isCentered>
              <Column100 isCentered>
                <FormLabel for="firstname">First name</FormLabel>
                <FormField 
                    type="text"
                    id="firstname"
                    name="firstname"
                    value={this.state.firstname} 
                    onChange={this.updateFirstName} 
                    />
                <FormLabel for="lastname">Last name</FormLabel>
                <FormField 
                      type="text"
                      id="lastname"
                      name="lastname"
                      value={this.state.lastname} 
                      onChange={this.updateLastName} 
                  />
                  <FormLabel for="email">*Email address</FormLabel>
                  <FormField 
                      type="email" 
                      id="email"
                      name="email"
                      value={this.state.email} 
                      onChange={this.updateEmail} 
                      required
                  />
                  <StyledCheckboxGroup>
                      <input type="checkbox" name="agree" checked={this.state.agree} onChange={this.updateAgreement} required/>
                      <label>{this.props.checkboxText}</label>
                  </StyledCheckboxGroup>
                  <ButtonContainer isCentered>
                      <FormButton type="submit" buttonText={this.props.buttonText} buttonLink={this.props.buttonLink} />
                  </ButtonContainer>
              </Column100>
          </FormRow>
      </StyledForm>
    </div>
  );
}
}

export default AirtableForm;